import { defineMessages } from "react-intl";

export const scope = "components.SolutionContent";

export const messages = defineMessages({
  PeopleImage: {
    id: `${scope}.solutionTitle`,
    defaultMessage: "Soluciones para tu empresa",
  },
  solutionSubtitle: {
    id: `${scope}.solutionSubtitle`,
    defaultMessage:
      "Con LB Finanzas vas a poder simplificar cobros y pagos internacionales,{br} resguardar reservas a través de dólares bancarios y generar{br} rendimientos de tus reservas.",
  },
});
